<template>
  <div class="giphy-wrapper">
    <input :value="searchQuery" type="text" placeholder="Search" @keyup.enter="searchGifs($event.target.value)" />
    <image-list :images="searchResults" :selected="selected" @changed="handleGifChanged"></image-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ImageList from '@/components/ImageList'

export default {
  components: {
    ImageList
  },
  props: {
    query: String
  },
  data() {
    return {
      selected: {}
    }
  },
  created() {
    if(this.query) {
      this.searchGifs(this.query)
    }
  },
  computed: {
    ...mapGetters(['searchResults', 'searchQuery'])
  },
  methods: {
    ...mapActions(['searchGifs']),
    handleGifChanged(gif) {
      this.selected = gif
    }
  }
}
</script>

<style lang="scss">
</style>
