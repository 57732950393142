import { client } from "@/api";

const MENU_CLOSE_DELAY = 300;

const state = {
  availableModes: {},
  pendingMode: null,
  currentMode: null
};

const mutations = {
  SET_AVAILABLE_MODES(state, modes) {
    state.availableModes = { ...modes };
  },
  SET_MODE_REQUEST(state, mode) {
    state.pendingMode = mode;
  },
  SET_MODE(state, mode) {
    state.currentMode = mode;
    state.pendingMode = null;
  },
  SET_MODE_FAILURE(state) {
    state.pendingMode = null;
  }
};

const actions = {
  async fetchModes({ commit }) {
    const modes = await client.fetchModes();
    commit("SET_AVAILABLE_MODES", modes);
  },

  async getCurrentMode({ commit }) {
    const mode = await client.getCurrentMode();
    commit("SET_MODE", mode);

    return mode;
  },

  async setMode({ commit, dispatch }, mode) {
    try {
      commit("SET_MODE_REQUEST", mode);
      await client.setMode(mode);
      if (window.innerWidth < 1024) {
        setTimeout(() => dispatch("closeMenu"), MENU_CLOSE_DELAY);
      }
    } catch (e) {
      commit("SET_MODE_FAILURE", e);
    }
  },

  subscribeToMode({ commit }) {
    client.subscribeToCurrentMode(mode => {
      commit("SET_MODE", mode);
    });
  }
};

const getters = {
  availableModes(state) {
    return state.availableModes;
  },
  currentMode(state) {
    return state.pendingMode || state.currentMode;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
