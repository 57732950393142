import { auth } from "./firebase";

export default {
  login(credentials) {
    const { username, password } = credentials;
    return auth.signInWithEmailAndPassword(username, password);
  },

  logout() {
    return auth.signOut();
  },

  getAuthStatus() {
    return new Promise(resolve => {
      auth.onAuthStateChanged(user => {
        resolve(user);
      });
    });
  },

  onAuthStateChanged(callback) {
    auth.onAuthStateChanged(callback);
  },

  currentUser() {
    return auth.currentUser;
  }
};
