<template>
  <div id="app" :class="{ 'menu-open': isMenuOpen }">
    <transition name="fade">
      <side-menu v-if="currentUser" v-show="isMenuOpen"></side-menu>
    </transition>
    <div class="page">
      <transition name="fade" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <nav-header></nav-header>
  </div>
</template>

<style lang="scss">
html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 20px;
  font-family: "avenir next", avenir, helvetica, "helvetica neue", Ubuntu, arial,
    sans-serif;
  letter-spacing: 3px;
  background-color: #111;
}

#app {
  overflow-x: hidden;
  width: 100%;
}

.page {
  text-align: center;
  transform: translateX(0);
  box-sizing: border-box;
  background-color: #111;
  padding-top: 50px;
  min-height: 100vh;
  width: 100%;

  transition: all 0.3s ease;
}

.menu-open {
  .page {
    transform: translateX(-300px);
  }
}

@media only screen and (min-width: 1024px) {
  .menu-open {
    .page {
      transform: translateX(0);
      width: calc(100% - 300px);
    }
  }
}
</style>

<script>
import NavHeader from "./components/NavHeader";
import SideMenu from "./components/SideMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    NavHeader,
    SideMenu
  },
  computed: {
    ...mapGetters(["currentUser", "isMenuOpen"])
  },
  methods: {
    ...mapActions(["getAuthStatus", "openMenu", "closeMenu"]),
    onResize() {
      if (window.innerWidth >= 1024) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    }
  },
  async created() {
    this.getAuthStatus();
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }
};
</script>
