<template>
  <div class="login-wrapper">
    <input v-model="username" type="text" placeholder="Username"/>
    <input v-model="password" type="password" placeholder="Password"/>
    
    <rand-button @click="handleLoginClick(username, password)">LOGIN</rand-button>
    <transition name="fade">
        <p class="error" v-if="authError">{{authError.message}}</p>
    </transition>
  </div>
</template>

<style lang="scss">
    .login-wrapper {
        padding: 20px;
        max-width: 400px;
        max-height: 260px;
        margin: auto;
        position: absolute;
        top: 50px;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .error {
        font-size: 0.7em;
        letter-spacing: 1px;
        color: #82f9da;
    }

    input[type="text"],
    input[type="password"] {
        display: block;
        padding: 8px;
        font-size: 1em;
        border: none;
        border-bottom: 1px solid teal;
        background: transparent;
        color: #ffffff;
        box-sizing: border-box;
        margin-bottom: 10px;
        width: 100%;
        font: inherit;
        letter-spacing: 1.2px;

        &:focus {
            outline:none;
        }

        &::placeholder {
            font-size: 0.7em;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
    }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import RandButton from '@/components/RandButton'

export default {
  components: {
    RandButton
  },
  data() {
    return {
        username: '',
        password: ''
    }
  },
  computed: {
    ...mapGetters(['authError'])
  },
  methods: {
    ...mapActions(['login']),
    handleLoginClick(username, password) {
        this.login({username, password})
    }
  }
}
</script>
