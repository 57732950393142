import { db, timestamp, subscribe } from './firebase'

const clientRef = db.ref('/clients/rand')
const gifsRef = db.ref('/gifs')

export default {

    fetchModes() {
        const query = clientRef.child('availableModes').orderByKey()
        return query.once('value')
           .then(snap => snap.val())
    },

    subscribeToCurrentMode(callback) {
        subscribe('/clients/rand/mode', callback)
    },

    setMode(mode) {
        return clientRef.child('mode').set(mode)
    },

    getCurrentMode() {
        return clientRef.child('mode').once('value').then(snap => snap.val())
    },

    fetchGifs() {
        const query = gifsRef.orderByKey().limitToLast(100)
        return query.once('value')
           .then(snap => snap.val())
    },

    subscribeToCurrentGif(callback) {
        subscribe('/clients/rand/data/gif', callback)
    },

    deleteGif(gif) {
        return gifsRef.child(gif.key).set(null)
    },

    setGif(gif) {
        const modified = timestamp
        return Promise.all([
            clientRef.child('data/gif').set(gif),
            gifsRef.child(gif.key + '/modified').set(modified)
        ])
    }
}
