import config from '@/config'

const GIPHY_API = 'https://api.giphy.com/v1'

function giphyRequest(path, options) {
    const url = new URL(GIPHY_API + path)
    url.searchParams.append("api_key", config.giphy.apiKey)

    return fetch(url, options)
            .then(res => res.json())
            .then(payload => payload.data)
            .catch(e => console.error(e))
}

export default {
    search(query) {
        return giphyRequest(`/gifs/search?q=${query}`)            
    }
}
