<template>
  <div class="nav-wrapper">
    <header>
      <transition name="fade">
        <span v-if="currentUser" class="menu-icon" @click="toggleMenu"></span>
      </transition>
      RAND
    </header>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    ...mapActions(['toggleMenu'])
  }
}
</script>

<style>
header {
  padding: 12px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.menu-icon {
  background: url(/menu-icon.svg) no-repeat center;
  background-size: 20px 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
}

.nav-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
}

</style>
