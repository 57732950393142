import { giphy } from '@/api'
import router from '@/router'

const state = {
  query: null,
  results: []
}

const mutations = {
  SEARCH_GIF_REQUEST (state, query) {
    state.query = query
  },
  SEARCH_GIF_SUCCESS (state, results) {
    state.results = results
  },
  SEARCH_GIF_FAILURE (state, error) {
    state.error = error
  }
}

const actions = {
  async searchGifs ({ commit }, query) {
    try {
      commit('SEARCH_GIF_REQUEST', query)
      router.replace({query: {q: query}})
      const results = await giphy.search(query)
      commit('SEARCH_GIF_SUCCESS', results)
    } catch(error) {
      commit('SEARCH_GIF_FAILURE', error)
    }
  }
}

const getters = {
  searchQuery(state) {
    return state.query
  },
  searchResults(state) {
    return state.results.map(item => {
      return {
        key: item.id,
        ...item.images.downsized
      }
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
