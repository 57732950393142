<template>
  <div class="image-wrapper" :style="wrapperStyle" :class="{show: aspectRatio}">
    <transition name="fade">
      <img :src="src" v-show="loaded" class="image" ref="image"/>
    </transition>
    <transition name="fade">
      <spinner v-if="!loaded" />
    </transition>
  </div>
</template>

<script>
import Spinner from './Spinner'
export default {
  components: {
    Spinner
  },
  props: {
    src: null
  },
  data() {
    return {
      loaded: false,
      aspectRatio: null
    }
  },
  computed: {
    wrapperStyle () {
      const height = 100 * (this.aspectRatio || 1 );
      return {
        paddingBottom: `${height}%`
      }
    }
  },
  mounted() {
    this.loadImage()
  },
  beforeDestroy() {
    clearInterval(this.poll)
  },
  methods: {
    loadImage() {
      const image = this.$refs.image

      image.onload = () => {
        this.loaded = true
      }

      this.poll = setInterval(() => {
          if (image.naturalWidth) {
              clearInterval(this.poll)
              this.aspectRatio = image.naturalHeight / image.naturalWidth
          }
      }, 10)
    }
  }
}
</script>

<style lang="scss">
.image-wrapper {
  position: relative;
  background: #222;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.show {
    opacity: 1;
  }
}

.image {
  display: block;
  position: absolute;
  width: 100%;
  height: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
