<template>
  <div class="image-list-wrapper">
    <div
      v-for="item in images"
      @click="handleImageClicked(item, $event)"
      class="item"
      :key="item.key"
    >
      <image-loader :src="item.url" />
      <div v-if="selected.url === item.url" class="selected" />
      <slot :item="item" />
    </div>
  </div>
</template>

<style lang="scss">
.image-list-wrapper {
  max-width: 500px;
  margin: auto;

  .item {
    position: relative;
    margin-bottom: 20px;

    .selected {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 10px solid pink;
    }

    .delete-btn {
      background: #333;
      padding: 5px;
      position: absolute;
      top: 0;
      left: 0;
      color: pink;
      text-transform: uppercase;
      font-size: 0.5em;
    }
  }
}
</style>

<script>
import ImageLoader from "@/components/ImageLoader";
export default {
  components: {
    ImageLoader
  },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    handleImageClicked(image, event) {
      this.scrollToTarget(event.currentTarget);
      this.$emit("changed", image);
    },
    scrollToTarget(target) {
      const targetRect = target.getBoundingClientRect();
      const containerHeight = document.documentElement.clientHeight;
      const scrollOffset = (containerHeight - targetRect.height) / 2;
      window.scrollTo(0, targetRect.top + window.scrollY - scrollOffset);
    }
  }
};
</script>
