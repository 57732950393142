import Vue from "vue";
import { client } from "@/api";

const state = {
  gifs: {},
  pendingGif: null,
  currentGif: {}
};

const mutations = {
  SET_AVAILABLE_GIFS(state, gifs) {
    state.gifs = { ...gifs };
  },
  SET_GIF_REQUEST(state, data) {
    state.pendingGif = data;
  },
  SET_GIF(state, data) {
    state.currentGif = data;
    state.pendingGif = null;
  },
  DELETE_GIF(state, key) {
    Vue.delete(state.gifs, key);
  }
};

const actions = {
  async fetchGifs({ commit }) {
    const gifs = await client.fetchGifs();
    commit("SET_AVAILABLE_GIFS", gifs);
  },

  async setGif({ commit }, gif) {
    commit("SET_GIF_REQUEST", gif);
    await client.setGif(gif);
  },

  async deleteGif({ commit, getters, dispatch }, gif) {
    await client.deleteGif(gif);
    commit("DELETE_GIF", gif.key);
    const { currentGif, gifsAsArray } = getters;

    if (gif.key === currentGif.key) {
      const nextGif = gifsAsArray[0];
      dispatch("setGif", nextGif);
    }
  },

  subscribeToGifs({ commit }) {
    client.subscribeToCurrentGif(gif => {
      commit("SET_GIF", gif);
    });
  }
};

const getters = {
  gifsAsArray(state) {
    return Object.keys(state.gifs).map(key => {
      return {
        key,
        ...state.gifs[key]
      };
    });
  },
  currentGif(state) {
    return state.pendingGif || state.currentGif;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
