import Vue from 'vue'
import Router from 'vue-router'

import { auth, client } from '@/api'

import Login from '@/views/Login'
import Gifs from '@/views/Gifs'
import GifSearch from '@/views/GifSearch'
import Draw from '@/views/Draw'
import Wave from '@/views/Wave'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: [
    {
      path: '*',
      redirect: '/gif'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/gif',
      component: Gifs,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'search',
          component: GifSearch,
          props: (route) => {
            return { query: route.query.q }
          },
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/wave',
      component: Wave,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/draw',
      component: Draw,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

let firstRun = true

async function checkAuth(to, from, next) {
  let currentUser = auth.currentUser()
  if(!currentUser) {
    currentUser = await auth.getAuthStatus()
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if(requiresAuth && !currentUser) next('login')
  else next()
}

async function checkMode(to, from, next) {
  if(firstRun) {
    firstRun = false
    try {
      const mode = await client.getCurrentMode()
      next(`/${mode.toLowerCase()}`)
    } catch(e) {
      return next()
    }
  } else next()
}

router.beforeEach(checkAuth)
router.beforeEach(checkMode)

export default router
