<template>
  <div class="color-slider-wrapper">
    <div class="color-slider__inner">
      <div class="control">
        <input
          type="range"
          min="0"
          max="360"
          v-model.number="h"
          @input="handleChange"
          :style="gradientH"
        />
      </div>
      <div class="control">
        <input
          type="range"
          min="0"
          max="100"
          v-model.number="s"
          @input="handleChange"
          :style="gradientS"
        />
      </div>
      <div class="control">
        <input
          type="range"
          min="0"
          max="100"
          v-model.number="v"
          @input="handleChange"
          :style="gradientV"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.color-slider-wrapper {
  .color-slider__inner {
    width: 100%;
    padding: 26px 1rem;
    box-sizing: border-box;
    background: #333;
  }

  .control + .control {
    margin-top: 1rem;
  }
}

input {
  width: 100%;
  margin: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  border-radius: 12px;
}

input[type="range"]::-webkit-slider-runnable-track {
  border: none;
  height: 12px;
  border-radius: 12px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #ddd;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
  margin-top: -10px;
}

input[type="range"]:focus {
  outline: none;
}
</style>

<script>
import tinycolor from "tinycolor2";
export default {
  props: ["initialValue"],
  data() {
    const color = tinycolor(this.initialValue).toHsv();
    return {
      h: color.h,
      s: color.s * 100,
      v: color.v * 100
    };
  },
  computed: {
    gradientH() {
      var stops = [];
      for (var i = 0; i < 7; i++) {
        var h = i * 60;
        var c = tinycolor({ h: h, s: this.s / 100, v: this.v / 100 });
        stops.push(c.toHexString());
      }

      return {
        backgroundImage: toLinearGradient(stops)
      };
    },
    gradientS() {
      var stops = [];
      var c = tinycolor({ h: this.h, s: 0, v: this.v / 100 });
      stops.push(c.toHexString());

      c = tinycolor({ h: this.h, s: 1, v: this.v / 100 });
      stops.push(c.toHexString());

      return {
        backgroundImage: toLinearGradient(stops)
      };
    },
    gradientV() {
      var stops = [];
      var c = tinycolor({ h: this.h, s: this.s / 100, v: 0 });
      stops.push(c.toHexString());

      c = tinycolor({ h: this.h, s: this.s / 100, v: 1 });
      stops.push(c.toHexString());

      return {
        backgroundImage: toLinearGradient(stops)
      };
    }
  },
  methods: {
    handleChange() {
      const c = tinycolor({ h: this.h, s: this.s / 100, v: this.v / 100 });
      this.$emit("input", c.toHexString());
    }
  }
};

function toLinearGradient(stops) {
  return `linear-gradient(to right, ${stops.join(", ")})`;
}
</script>
