<template>
  <div class="gifs-wrapper">
    <router-view></router-view>
    <image-list :images="gifs" :selected="current" @changed="setGif">
      <div 
        slot-scope="props"
        class="delete-btn"
        @click.prevent.stop="handleDeleteClicked(props.item)">
        Delete
      </div>
    </image-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ImageList from '@/components/ImageList'
export default {
  components: {
    ImageList
  },
  created() {
    this.fetchGifs()
    this.subscribeToGifs()
  },
  computed: {
    ...mapGetters({
      gifs: 'gifsAsArray',
      current: 'currentGif'
    })
  },
  methods: {
    ...mapActions([
      'fetchGifs',
      'setGif',
      'deleteGif',
      'subscribeToGifs'
    ]),
    handleDeleteClicked(gif) {
      const answer = confirm("Are you sure you want to delete this gif?")
      if(answer) {
        this.deleteGif(gif)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
