<template>
  <div class="draw-wrapper" :class="{ pickerOpen: isPickerOpen }">
    <div class="canvas-wrapper">
      <canvas
        ref="canvas"
        @touchstart.prevent="onDown"
        @touchmove.prevent="onMove"
        @touchend.prevent="onUp"
        @mousedown.prevent="onDown"
        @mousemove.prevent="onMove"
        @mouseup.prevent="onUp"
      />
    </div>
    <div class="color-picker">
      <div
        class="color-swatch"
        :style="{ background: brushColor }"
        @click="onPickerClick"
      ></div>
      <color-slider
        :initialValue="brushColor"
        @input="updateColor"
      ></color-slider>
    </div>
    <div class="clearBtn" @click="clear">CLEAR</div>
  </div>
</template>

<style lang="scss">
.draw-wrapper {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: bottom 0.3s ease;

  .clearBtn {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    padding: 10px 14px;
    font-size: 0.5em;
    text-transform: uppercase;
    background: #333;
  }

  .color-swatch {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    margin: 10px;
  }

  .color-picker {
    position: fixed;
    bottom: 0;
    width: 100%;
    transform: translateY(165px);
    transition: all 0.3s ease;
  }

  &.pickerOpen {
    bottom: 165px;

    .color-picker {
      transform: translateY(0);
    }
  }
}

.canvas-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  canvas {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
    margin: 0;
  }
}

.canvas-wrapper::before {
  content: "";
  background: url(/ideo-logo.svg) no-repeat center;
  background-size: contain;
  opacity: 0.1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>

<script>
import { db } from "@/api/firebase";
import ColorSlider from "@/components/ColorSlider";

export default {
  components: {
    ColorSlider
  },
  data() {
    return {
      brushColor: "#ff0000",
      isPickerOpen: true
    };
  },
  created() {
    this.drawRef = db.ref("/clients/rand/data/draw");
    this.currentBrush = null;
    this.currentTouch = null;
    this.points = [];
  },
  mounted() {
    const canvas = this.$refs.canvas;
    const ctx = canvas.getContext("2d");

    const devicePixelRatio = window.devicePixelRatio || 1;
    const backingStoreRatio =
      ctx.webkitBackingStorePixelRatio ||
      ctx.mozBackingStorePixelRatio ||
      ctx.msBackingStorePixelRatio ||
      ctx.oBackingStorePixelRatio ||
      ctx.backingStorePixelRatio ||
      1;

    const ratio = devicePixelRatio / backingStoreRatio;

    const canvasWidth = Math.max(window.innerWidth, window.innerHeight);
    const canvasHeight = canvasWidth;

    if (devicePixelRatio !== backingStoreRatio) {
      canvas.width = canvasWidth * ratio;
      canvas.height = canvasHeight * ratio;

      ctx.scale(ratio, ratio);
    } else {
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
    }

    this.ctx = ctx;
    this.canvas = canvas;
    this.setBrush({
      strokeStyle: this.brushColor
    });
  },
  methods: {
    getTouch(event) {
      if (event.changedTouches) {
        return event.changedTouches[0];
      }
      const { clientX, clientY } = event;
      return {
        clientX,
        clientY,
        identifier: 0
      };
    },
    getNormalizedTouch(event) {
      const touch = this.getTouch(event);
      const { target } = event;
      const rect = target.getBoundingClientRect();

      return {
        x: (touch.clientX - rect.left) / rect.width,
        y: (touch.clientY - rect.top) / rect.height,
        identifier: touch.identifier
      };
    },
    onPickerClick() {
      this.isPickerOpen = !this.isPickerOpen;
    },
    onDown(event) {
      if (this.currentTouch) return;
      const touch = this.getNormalizedTouch(event);

      this.points.push(touch);
      this.currentTouch = touch;

      requestAnimationFrame(this.drawPoints);

      this.sendData("touchstart", touch);
    },
    onUp(event) {
      const touch = this.getNormalizedTouch(event);
      if (
        this.currentTouch &&
        this.currentTouch.identifier !== touch.identifier
      )
        return;
      this.currentTouch = null;
      this.points.length = 0;

      this.sendData("touchend", touch);
    },
    onMove(event) {
      if (!this.currentTouch) return;
      const touch = this.getNormalizedTouch(event);
      this.points.push(touch);
      requestAnimationFrame(this.drawPoints);

      this.sendData("touchmove", touch);
    },
    drawPoints() {
      const { canvas, ctx, points } = this;
      if (!points.length) {
        return;
      }

      const canvasWidth = canvas.width / window.devicePixelRatio;
      const canvasHeight = canvas.height / window.devicePixelRatio;

      ctx.beginPath();
      ctx.moveTo(points[0].x * canvasWidth, points[0].y * canvasHeight);

      let i = 1;
      for (const length = points.length; i < length - 1; i++) {
        const c =
            (points[i].x * canvasWidth + points[i + 1].x * canvasWidth) / 2,
          d = (points[i].y * canvasHeight + points[i + 1].y * canvasHeight) / 2;
        ctx.quadraticCurveTo(
          points[i].x * canvasWidth,
          points[i].y * canvasHeight,
          c,
          d
        );
      }

      if (points.length == 1) {
        ctx.lineTo(points[0].x * canvasWidth, points[0].y * canvasHeight);
      }

      ctx.stroke();
    },
    sendData(type, point) {
      const { currentBrush } = this;
      const data = {
        type,
        ...point,
        ...currentBrush
      };
      this.drawRef.set(data);
    },
    updateColor(color) {
      this.brushColor = color;
      this.setBrush({
        strokeStyle: color
      });
    },
    setBrush(brush) {
      const { ctx } = this;
      const newBrush = {
        lineWidth: 10,
        lineCap: "round",
        lineJoin: "round",
        strokeStyle: "#ffffff",
        ...brush
      };

      ctx.lineWidth = newBrush.lineWidth;
      ctx.lineCap = newBrush.lineCap;
      ctx.lineJoin = newBrush.lineJoin;
      ctx.strokeStyle = newBrush.strokeStyle;

      this.currentBrush = newBrush;
    },
    clear() {
      const { canvas, ctx } = this;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.drawRef.child("type").set("clear");
    }
  }
};
</script>
