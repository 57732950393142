import { auth, client } from '@/api'
import router from '@/router'

const state = {
  isRequestingAuth: false,
  currentUser: null,
  error: null
}

const mutations = {
  LOGIN_REQUEST (state) {
    state.isRequestingAuth = true
  },
  LOGIN_FAILED (state, error) {
    state.isRequestingAuth = false
    state.error = error
  },
  LOGIN_SUCCESS (state) {
    state.isRequestingAuth = false
    state.error = null
  },
  SET_USER (state, user) {
    state.currentUser = user
  }
}

const actions = {

  async login({commit}, credentials) {
    commit('LOGIN_REQUEST')
    try {
      const user = await auth.login(credentials)
      commit('LOGIN_SUCCESS')
      commit('SET_USER', user)

      const currentMode = await client.getCurrentMode()
      router.replace(`/${currentMode.toLowerCase()}`)
    } catch(error) {
      commit('LOGIN_FAILED', error)
    }
  },

  async logout({commit, dispatch}) {
    try{
      await auth.logout()
      commit('SET_USER', null)
      dispatch('closeMenu')

      router.replace('/login')
    } catch(error) {
      console.error(error)
    }
  },

  async getAuthStatus({commit}) {
    const user = await auth.getAuthStatus()
    commit('SET_USER', user)
    return user
  }
}

const getters = {
  currentUser(state) {
    return state.currentUser
  },
  authError(state) {
    return state.error
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
