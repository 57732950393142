import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import gifs from './modules/gifs'
import modes from './modules/modes'
import search from './modules/search'

Vue.use(Vuex)

const state = {
  isMenuOpen: false
}

const mutations = {
  OPEN_MENU (state) {
    state.isMenuOpen = true
  },
  CLOSE_MENU (state) {
    state.isMenuOpen = false
  }
}

const actions = {
  closeMenu({commit}) {
    commit('CLOSE_MENU')
  },
  openMenu({commit}) {
    commit('OPEN_MENU')
  },
  toggleMenu ({ state, commit }) {
    if(state.isMenuOpen) {
      commit('CLOSE_MENU')
    } else {
      commit('OPEN_MENU')
    }
  }
}

const getters = {
  isMenuOpen(state) {
    return state.isMenuOpen
  }
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    gifs,
    modes,
    search
  }
})

export default store
