import config from "@/config";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const firebaseApp = firebase.initializeApp(config.firebase);

export const auth = firebaseApp.auth();
export const db = firebaseApp.database();
export const timestamp = firebase.database.ServerValue.TIMESTAMP;

export const subscribe = function subscribe(ref, callback) {
  return db.ref(ref).on("value", snap => {
    callback(snap.val());
  });
};

export default firebaseApp;
