<template>
  <div class="menu">
    <transition-group name="fade">
      <div v-for="mode in availableModes" :key="mode" class="menu-item" :class="{active: mode === currentMode}" @click="handleItemClick(mode)">
        {{mode}}
      </div>
    </transition-group>

    <rand-button @click="handleLogoutClick()">
      Logout
    </rand-button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RandButton from '@/components/RandButton'

export default {
  components: {
    RandButton
  },
  computed: {
    ...mapGetters(['availableModes', 'currentMode'])
  },
  created() {
    this.fetchModes()
    this.subscribeToMode()
  },
  methods: {
    ...mapActions(['fetchModes', 'setMode', 'subscribeToMode', 'logout']),
    handleItemClick(item) {
      this.setMode(item)
      this.$router.push(item.toLowerCase())
    },
    handleLogoutClick() {
      this.logout()
    }
  }
}
</script>

<style lang="scss">

.menu {
  position: fixed;
  right: 0;
  padding-top: 50px;
  width: 300px;
  height: 100vh;
  background: #222;
  box-sizing: border-box;
  color: white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.menu-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  font-size: 0.9em;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;

  transition: all 0.1s ease;

  &.active {
    background: #444;
    padding-left: 40px;
  }
}

</style>
